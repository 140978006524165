import './payment.scss'
import './altapayMulti.scss'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import * as orderActions from '../../../actions/order-actions'
import * as customerActions from '../../../actions/customer-actions'
import * as gaActions from '../../../actions/ga-actions'
import { appHeightChanged } from '../../../actions/app-actions'
import { ROUTES } from '../../navigation/route-constants'
import CONSTANTS from '../orderflow-constants'
import CreditCards from './CreditCards'
import LOCALE from '../giftcards/GiftCardLocale'
import xml2js from '../../../lib/xml-to-js'

import GiftCardContainerAlta from '../giftcards/GiftCardContainerAlta'

class AltaPaymentContainerMulti extends React.Component {
    constructor(props) {
        super(props);
        localStorage.removeItem('beforeDibsState')
        this.state = {
            errors: {
                creditCard: '',
                disableBack: '',
               
            },
            paymentMethodLoad: true,
          
        }

        const { configuration, showtime, history } = props;
        if (!configuration.giftCardsEnabled && !configuration.payWithDibs)
            history.replace(`${ROUTES.ORDERFLOW.REEPAY}/${showtime.movieId}/${showtime.id}${history.location.search}`);

        this.giftCardPaymentClicked = this.giftCardPaymentClicked.bind(this);
        this.navBack = this.navBack.bind(this);
        this.navNext = this.navNext.bind(this);
        
    }

    componentDidMount() {
        
      // console.log(actions)
        const { iframeId, siteType, customer, actions, history, sessionState, showtime, organizerId, transactionId, checkoutComplete, origin, paymentMethods } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId);

       
                const preBook = this.props.location.preBook_parameters;
                const type = siteType ? `&sitetype=${siteType}` : '';
            //    console.log(preBook, 'prebook')
                //DONE: Get this from a backend endpoint
                const token = preBook.altaPayToken;
                const m = preBook.merchant;
               // console.log(token)
                //DONE: Define the callbacks with the right endpoints
                // Using buy.ebillet.dk instead of dev.ebillet.dk, because it is AltaPay calling the accepturl, and dev.ebillet.dk is not accesible from outside of ebillet office
                let callback = (window.location.origin.startsWith(`http://localhost`) ? (m != null && m.includes('altapay') ? `https://buy.ebillet.dk` : `http://dev.ebillet.dk`) : window.location.origin) + `/systemnative/ticketgate.asmx/KvitteringAP?org=${organizerId}&amount=${preBook.amount}&iframeid=${iframeId}${type}&transactionID=${transactionId}&id=&invoice=${preBook.orderid}&customer=${customer.email}`
                let acceptUrl = callback + '&accept=true';
                let cancelUrl = callback + '&cancel=true';

                const importScript = document.createElement("script");
                if (m != null && m.includes('test')) {
                    importScript.src = "https://testgateway.altapaysecure.com/checkout/v1/js/AltaPaySDK.js";
                }
                else {
                    importScript.src = "https://ebillet.altapaysecure.com/checkout/v1/js/AltaPaySDK.js";
                }
                importScript.type = "module";
                importScript.async = true; 

                importScript.onload = function () {
                    let paymentSession = window.Altapay.init(token);
                    paymentSession.setCallbacks(window.AltaPayFactory.getCallbacksBuilder()
                        .setSuccess(acceptUrl)
                        .setFailure(cancelUrl)
                        .setBodyFormat("JSON_PARAMETER")
                        .build());
                    paymentSession.setOrder(
                        window.AltaPayFactory.getOrder(
                            preBook.orderid,
                            window.AltaPayFactory.getAmount(preBook.amount / 100, 'DKK'),
                            [],
                            AltaPayFactory.getCustomer(
                                customer.name.split(" ")[0],
                                customer.name.split(" ")[customer.name.split(" ").length - 1],
                                customer.email,
                                customer.phone
                            ),
                            null,
                            null
                        )
                    );
                    //TODO: Set shop name based on cinema you are processing the transaction for
                    paymentSession.setConfiguration(window.AltaPayFactory.getConfigurationBuilder()
                        .setShopName('Ebillet')
                        .build()
                    );

                    paymentSession.onRenderPaymentMethodCallback(function (onRenderPaymentMethodEvent) {
                        const payment = onRenderPaymentMethodEvent.paymentMethod
                        const paymentMethodElement = document.createElement('div')
                        paymentMethodElement.className = 'payment-item-container'
                        paymentMethodElement.id = payment.id
                        paymentMethodElement.setAttribute('data-payment-method', payment.description)
                        const paymentItemElement = document.createElement('div')
                        paymentItemElement.className = 'payment-item'

                        const paymentLogoElement = document.createElement('img')
                        paymentLogoElement.className = 'payment-logo'
                        paymentLogoElement.src = payment.logoUrl
                        paymentLogoElement.alt = payment.description

                        const paymentDescriptionElement = document.createElement('span')
                        paymentDescriptionElement.className = 'payment-description'
                        paymentDescriptionElement.textContent = payment.description

                        paymentItemElement.appendChild(paymentLogoElement)
                        paymentItemElement.appendChild(paymentDescriptionElement)

                        const radioInputElement = document.createElement('input')
                        radioInputElement.type = 'radio'
                        radioInputElement.name = 'payment-method'
                        radioInputElement.value = payment.description

                        radioInputElement.addEventListener('change', function (ev) {

                            const value = ev.currentTarget?.value
                            radioInputElement.checked = value === payment.description
                            radioInputElement.value = value
                            onRenderPaymentMethodEvent.onSelectPaymentMethodCallback()
                        })

                        paymentMethodElement.addEventListener('click', function (ev) {
                            radioInputElement.checked = true
                            radioInputElement.dispatchEvent(new Event('change'))
                            onRenderPaymentMethodEvent.onSelectPaymentMethodCallback()
                        })
                        paymentMethodElement.appendChild(radioInputElement)
                        paymentMethodElement.appendChild(paymentItemElement)

                        let spin = document.getElementsByClassName('spinner')
                            spin[0].style.display='none'
                       
                        return paymentMethodElement

                    })


                  
                   

                    paymentSession.injectPaymentMethods(document.getElementById('payment-methods'));
                    document.getElementById('payment-button').onclick = function () {
                        paymentSession.initiatePayment();

                        

                    };
                    
                };  // on load end
        
        window.document.body.appendChild(importScript);
       
         
       
    }

    onRenderPaymentMethodCallback(method) {
       
       alert(method);
      
        
    }
   
    callbackSuccess() {

    }

    giftCardPaymentClicked() {
        const { showtime, organizerId, history } = this.props;
        history.push(`${ROUTES.ORDERFLOW.GIFTCARDS}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);
    }

    navBack() {
        const { history, disableBack, appLocale } = this.props;
        const { errors } = this.state;
        if (disableBack) this.setState({ errors: { ...errors, disableBack: LOCALE[appLocale].errorBack } });
        else history.goBack();
    }

    
    navNext() {
        const { organizerId, showtime, transactionId, customer, actions, totalPrice, history, giftCardAmount } = this.props;

        if (giftCardAmount > 0 && giftCardAmount < totalPrice && customer.creditCard || totalPrice === 0) {
            actions.preBook(transactionId, customer).then((result) => {
                if (!result.error) {
                    if (totalPrice === 0) {
                        actions.book(transactionId, 'Free', totalPrice)
                            .then((result) => { if (!result.error) history.push(`${ROUTES.ORDERFLOW.RECEIPT}/${showtime.movieId}/${showtime.id}?org=${organizerId}`) });
                    } else '';
                }
            });
        } else '';
    }

    paymentIsValid(payment) {
        const { totalPrice, appLocale, giftCardAmount } = this.props;
        if (totalPrice === 0) return true;
        else {
            const errors = {
                ...errors,
                creditCard: !payment.creditCard && (!giftCardAmount > 0 || giftCardAmount > 0 && giftCardAmount < totalPrice) ? LOCALE[appLocale].errorCreditCard : ''
            }

            this.setState({ errors });
            return errors.creditCard == '';
        }
    }
    
    render() {
        const { orderType, totalPrice, configuration, customer, actions, appLocale, disableBack, organizerId, history, showtime, creditCards, warning_texts } = this.props;
        const { errors, paymentMethodLoad } = this.state;
     //   console.log(paymentMethodLoad, 'payment method load')
        const data = this.props.location.state
        
        const event_name = data.eventName
        const total_tkt_price = data.totalTicketPrice
        const ticket_price = data.ticketPrice
        const extra = data.gebyr
        const ticket_count = data.ticketCount
        const ticket_count1 = data.ticketCount1
        const ticket_count2= data.ticketCount2
        const chooseCreditCard = totalPrice > 0 && (orderType === CONSTANTS.orderTypes.buy || orderType === CONSTANTS.orderTypes.buyReservation) && configuration.payWithDibs ?
            <CreditCards
                creditCards={creditCards}
                appLocale={appLocale}
                totalPrice={totalPrice}
                customer={customer}
                actions={actions}
                errors={errors}
            /> : null;

        return (
            <>
                <section style={{ textAlign: 'center', height: '300px', backgroundColor: '#2e2e2e', color: 'white', padding: '90px' }}>
                    <h1>{event_name}</h1>
                </section>
                <div className="AltaPaymentContainer">
                    <div className='content-container' style={{ minHeight:'400px' }}>
               

                    <div className='altaPayMulti'>

                            <p className='title'> {LOCALE[appLocale].buyTitle}</p>
                            <p className='desc'>{event_name}</p>
                            <form>
                                <p>Billetter  <span style={{ float: 'right' }}>{ticket_price}  {LOCALE[appLocale].currency}</span></p>
                                <div className="order">
                                    <span>{this.props.location.state.ticketCount  > 0 && this.props.location.state.ticketCount + ' x ' + this.props.location.state.ticketType}</span>
                                    <span>{this.props.location.state.ticketCount  > 0 && this.props.location.state.ticketCount1 > 0 && <br></br>}</span>
                                    <span>{this.props.location.state.ticketCount1 > 0 && this.props.location.state.ticketCount1 + ' x ' + this.props.location.state.ticketType1}</span>
                                    <span>{this.props.location.state.ticketCount1 > 0 && this.props.location.state.ticketCount2 > 0 && <br></br>}</span>
                                    <span>{this.props.location.state.ticketCount2 > 0 && this.props.location.state.ticketCount2 + ' x ' + this.props.location.state.ticketType2}</span>
                                    <span>{this.props.location.state.ticketCount2 > 0 && this.props.location.state.ticketCount3 > 0 && <br></br>}</span>
                                    <span>{this.props.location.state.ticketCount3 > 0 && this.props.location.state.ticketCount3 + ' x ' + this.props.location.state.ticketType3}</span>
                                    <span>{this.props.location.state.ticketCount3 > 0 && this.props.location.state.ticketCount4 > 0 && <br></br>}</span>
                                    <span>{this.props.location.state.ticketCount4 > 0 && this.props.location.state.ticketCount4 + ' x ' + this.props.location.state.ticketType4}</span>
                                    <span>{this.props.location.state.ticketCount4 > 0 && this.props.location.state.ticketCount5 > 0 && <br></br>}</span>
                                    <span>{this.props.location.state.ticketCount5 > 0 && this.props.location.state.ticketCount5 + ' x ' + this.props.location.state.ticketType5}</span>
                                </div>
                                <p className='extra'>Gebyr <span style={{ float: 'right' }}>{extra} {LOCALE[appLocale].currency}</span></p>
                                <p className='sum_container'>Sum <span style={{ float: 'right' }}>{total_tkt_price}{LOCALE[appLocale].currency} </span></p>
                            </form>

                    </div>


                    <div className='altaContainer'>
                            { /*  loading spinner */}
                            {paymentMethodLoad == true &&
                                <div style={{ position: 'relative', top: '30%' }}>
                                    <div class="spinner"></div>
                                </div>
                            }

                            <section className='alta_altaContainer'>
                                {total_tkt_price === 0 ? <div></div> :
                                    <>
                                       
                                        <p className='paymethod_text'>{LOCALE[appLocale].payMethod}</p>

                                <div className='paymentMethodContainer'>
                                    <div id='payment-methods' style={{ display: 'flex' }}> </div>
                                </div>
                                </>
                            }

                            </section>
                        <div className='theLastPriceCon'>
                            <p>  {LOCALE[appLocale].totalPrice} </p>
                                <p>{total_tkt_price} {LOCALE[appLocale].currency}</p>
                        </div>
                    </div>
                </div>
                <div className='btncontent-container'>
                    <input className='back_button' onClick={this.navBack} type='button' value={LOCALE[appLocale].back}></input>
                        <button id="payment-button">{LOCALE[appLocale].pay}</button>
                   
                </div>
                
             
                </div >
                </>

        )
    }
}

const mapStateToProps = (state, ownProps) => {
 //  console.log(state)
    return {
        organizerId: state.organizer.id,
        showtime: state.movielist.selectedShowtime,
        orderType: state.order.orderType,
        configuration: state.organizer.configuration,
        creditCards: state.organizer.creditcards || [],
        customer: state.customer,
        transactionId: state.order.selected.transactionId,
        receipt: state.receipt,
        totalPrice: state.order.totalPrice,
        clubCardId: state.user.clubCardId,
        appLocale: state.app.locale,
        disableBack: state.order.selected.giftCardAmount > 0,
        giftCardAmount: state.order.selected.giftCardAmount,
        iframeId: state.app.iframeId,
        warning_texts: state.organizer.configuration.texts
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, orderActions, customerActions, gaActions, { appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AltaPaymentContainerMulti)