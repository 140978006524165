import './payment.scss'
import './altapay.scss'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import * as orderActions from '../../../actions/order-actions'
import * as customerActions from '../../../actions/customer-actions'
import * as gaActions from '../../../actions/ga-actions'
import { appHeightChanged } from '../../../actions/app-actions'
import { ROUTES } from '../../navigation/route-constants'
import CONSTANTS from '../orderflow-constants'
import CreditCards from './CreditCards'
import LOCALE from './PaymentLocale'
import GiftCardContainerAlta from '../giftcards/GiftCardContainerAlta'
import { useLocation } from 'react-router-dom';

class AltaPaymentContainer extends React.Component {
   
    constructor(props) {
        super(props);
        localStorage.removeItem('beforeDibsState')
        this.state = {
            errors: {
                creditCard: '',
                disableBack: '',
               
            },
            paymentMethodLoad: true,

            lastPrice: -1
        }

        const { configuration, showtime, history } = props;
        if (!configuration.giftCardsEnabled && !configuration.payWithDibs)
            history.replace(`${ROUTES.ORDERFLOW.REEPAY}/${showtime.movieId}/${showtime.id}${history.location.search}`);

        this.giftCardPaymentClicked = this.giftCardPaymentClicked.bind(this);
        this.navBack = this.navBack.bind(this);
        this.navNext = this.navNext.bind(this);
        this.preBookRecall = this.preBookRecall.bind(this)
    }

    preBookRecall() {
       
        const { iframeId, siteType, customer, actions, history, sessionState, showtime, organizerId, transactionId, checkoutComplete, origin, paymentMethods, appLocale, totalPrice } = this.props;
        const license = sessionStorage.getItem('license')
        if (window.parent != window) actions.appHeightChanged(iframeId);
      
        this.state.lastPrice = totalPrice
      
        actions.preBook(transactionId, customer).then((result) => {

            if (!result.error) {
                const preBook = result.dibs.parameters;
                const type = siteType ? `&sitetype=${siteType}` : '';
              
                //DONE: Get this from a backend endpoint
                const token = result.dibs.parameters.altaPayToken;
                const m = result.dibs.parameters.merchant;
               
                //DONE: Define the callbacks with the right endpoints
                // Using buy.ebillet.dk instead of dev.ebillet.dk, because it is AltaPay calling the accepturl, and dev.ebillet.dk is not accesible from outside of ebillet office
                let callback = (window.location.origin.startsWith(`http://localhost`) ? (m != null && m.includes('altapay') ? `https://buy.ebillet.dk` : `http://dev.ebillet.dk`) : window.location.origin) + `/systemnative/ticketgate.asmx/KvitteringAP?org=${organizerId}&amount=${preBook.amount}&iframeid=${iframeId}${type}&transactionID=${transactionId}&id=&invoice=${preBook.orderid}&customer=${customer.phone}`
                let acceptUrl = callback + `&accept=true&LicensePlate=${license}`;
                let cancelUrl = callback + '&cancel=true';

                const importScript = document.createElement("script");
                if (m != null && m.includes('test')) {
                    importScript.src = "https://testgateway.altapaysecure.com/checkout/v1/js/AltaPaySDK.js";
                }
                else {
                    importScript.src = "https://ebillet.altapaysecure.com/checkout/v1/js/AltaPaySDK.js";
                }
                importScript.type = "module";
                importScript.async = true;

                importScript.onload = function () {
                    let paymentSession = window.Altapay.init(token);
                    paymentSession.setCallbacks(window.AltaPayFactory.getCallbacksBuilder()
                        .setSuccess(acceptUrl)
                        .setFailure(cancelUrl)
                        .setBodyFormat("JSON_PARAMETER")
                        .build());
                    paymentSession.setOrder(
                        window.AltaPayFactory.getOrder(
                            preBook.orderid,
                            window.AltaPayFactory.getAmount(preBook.amount / 100, 'DKK'),
                            [],
                            AltaPayFactory.getCustomer(
                                customer.name.split(" ")[0],
                                customer.name.split(" ")[customer.name.split(" ").length - 1],
                                customer.email,
                                customer.phone

                            ),
                            null,
                            null
                        )
                    );
                    //TODO: Set shop name based on cinema you are processing the transaction for
                    paymentSession.setConfiguration(
                        window.AltaPayFactory.getConfigurationBuilder()
                            .setShopName('Ebillet')
                            .setCountry('DK')
                            .setLanguage(appLocale)
                            .build()
                    );




                    paymentSession.onRenderPaymentMethodCallback(function (onRenderPaymentMethodEvent) {
                        //      console.log(onRenderPaymentMethodEvent.onSelectPaymentMethodCallback, 'event')
                        const payment = onRenderPaymentMethodEvent.paymentMethod
                        const paymentMethodElement = document.createElement('div')
                        paymentMethodElement.className = 'payment-item-container'
                        paymentMethodElement.id = payment.id
                        paymentMethodElement.setAttribute('data-payment-method', payment.description)
                        const paymentItemElement = document.createElement('div')
                        paymentItemElement.className = 'payment-item'

                        const paymentLogoElement = document.createElement('img')
                        paymentLogoElement.className = 'payment-logo'
                        paymentLogoElement.src = payment.logoUrl
                        paymentLogoElement.alt = payment.description

                        const paymentDescriptionElement = document.createElement('span')
                        paymentDescriptionElement.className = 'payment-description'
                        paymentDescriptionElement.textContent = payment.description

                        paymentItemElement.appendChild(paymentLogoElement)
                        paymentItemElement.appendChild(paymentDescriptionElement)

                        const radioInputElement = document.createElement('input')
                        radioInputElement.type = 'radio'
                        radioInputElement.name = 'payment-method'
                        radioInputElement.value = payment.description

                        radioInputElement.addEventListener('change', function (ev) {

                            const value = ev.currentTarget?.value
                            radioInputElement.checked = value === payment.description
                            radioInputElement.value = value
                            onRenderPaymentMethodEvent.onSelectPaymentMethodCallback()
                        })

                        paymentMethodElement.addEventListener('click', function (ev) {
                            radioInputElement.checked = true
                            radioInputElement.dispatchEvent(new Event('change'))
                            onRenderPaymentMethodEvent.onSelectPaymentMethodCallback()

                        })

                        paymentMethodElement.appendChild(radioInputElement)
                        paymentMethodElement.appendChild(paymentItemElement)

                        if (window.parent != window) actions.appHeightChanged(iframeId);
                        return paymentMethodElement
                    })


                    paymentSession.injectPaymentMethods(document.getElementById('payment-methods'));
                    

                    document.getElementById('payment-button').onclick = function () {

                        paymentSession.initiatePayment();

                    };
                             
                };         // import script ends here

                window.document.body.appendChild(importScript);

                this.setState({ paymentMethodLoad: false })
                
            }
        });
       
    }
    
    componentDidMount() {
        const { iframeId, siteType, customer, actions, history, sessionState, showtime, organizerId, transactionId, checkoutComplete, origin, paymentMethods, appLocale, totalPrice } = this.props;
        
        const license = sessionStorage.getItem('license')
     
        if (window.parent != window) actions.appHeightChanged(iframeId, true);
           actions.preBook(transactionId, customer).then((result) => {
            
            if (!result.error) {
                const preBook = result.dibs.parameters;
                const type = siteType ? `&sitetype=${siteType}` : '';
                console.log(result, 'pre book')
                //DONE: Get this from a backend endpoint
                const token = result.dibs.parameters.altaPayToken;
                const m = result.dibs.parameters.merchant;
                this.state.finalTktPrice = preBook.amount / 100
                //DONE: Define the callbacks with the right endpoints
                // Using buy.ebillet.dk instead of dev.ebillet.dk, because it is AltaPay calling the accepturl, and dev.ebillet.dk is not accesible from outside of ebillet office
                let callback = (window.location.origin.startsWith(`http://localhost`) ? (m != null && m.includes('altapay') ? `https://buy.ebillet.dk` : `http://dev.ebillet.dk`) : window.location.origin) + `/systemnative/ticketgate.asmx/KvitteringAP?org=${organizerId}&amount=${preBook.amount}&iframeid=${iframeId}${type}&transactionID=${transactionId}&id=&invoice=${preBook.orderid}&customer=${customer.phone}`
                let acceptUrl = callback + `&accept=true&LicensePlate=${license}`;
                let cancelUrl = callback + '&cancel=true';

                const importScript = document.createElement("script");
                if (m != null && m.includes('test')) {
                    importScript.src = "https://testgateway.altapaysecure.com/checkout/v1/js/AltaPaySDK.js";
                }
                else {
                    importScript.src = "https://ebillet.altapaysecure.com/checkout/v1/js/AltaPaySDK.js";
                }
                importScript.type = "module";
                importScript.async = true; 

                importScript.onload = function () {
                    let paymentSession = window.Altapay.init(token);
                    paymentSession.setCallbacks(window.AltaPayFactory.getCallbacksBuilder()
                        .setSuccess(acceptUrl)
                        .setFailure(cancelUrl)
                        .setBodyFormat("JSON_PARAMETER")
                        .build());
                    paymentSession.setOrder(
                        window.AltaPayFactory.getOrder(
                            preBook.orderid,
                            window.AltaPayFactory.getAmount(preBook.amount / 100, 'DKK'),
                            [],
                            AltaPayFactory.getCustomer(
                                customer.name.split(" ")[0],
                                customer.name.split(" ")[customer.name.split(" ").length - 1],
                                customer.email,
                                customer.phone
                                
                            ),
                            null,
                            null
                        )
                    );
                    //TODO: Set shop name based on cinema you are processing the transaction for
                    paymentSession.setConfiguration(
                        window.AltaPayFactory.getConfigurationBuilder()
                            .setShopName('Ebillet')
                            .setCountry('DK')
                            .setLanguage(appLocale)
                        .build()
                    );
                   
                  
                   

                    paymentSession.onRenderPaymentMethodCallback(function (onRenderPaymentMethodEvent) {
                  //      console.log(onRenderPaymentMethodEvent.onSelectPaymentMethodCallback, 'event')
                        const payment = onRenderPaymentMethodEvent.paymentMethod
                        const paymentMethodElement = document.createElement('div')
                        paymentMethodElement.className = 'payment-item-container'
                        paymentMethodElement.id = payment.id
                        paymentMethodElement.setAttribute('data-payment-method', payment.description)
                        const paymentItemElement = document.createElement('div')
                        paymentItemElement.className = 'payment-item'

                        const paymentLogoElement = document.createElement('img')
                        paymentLogoElement.className = 'payment-logo'
                        paymentLogoElement.src = payment.logoUrl
                        paymentLogoElement.alt = payment.description

                        const paymentDescriptionElement = document.createElement('span')
                        paymentDescriptionElement.className = 'payment-description'
                        paymentDescriptionElement.textContent = payment.description

                        paymentItemElement.appendChild(paymentLogoElement)
                        paymentItemElement.appendChild(paymentDescriptionElement)

                        const radioInputElement = document.createElement('input')
                        radioInputElement.type = 'radio'
                        radioInputElement.name = 'payment-method'
                        radioInputElement.value = payment.description

                        radioInputElement.addEventListener('change', function (ev) {
                         
                            const value = ev.currentTarget?.value
                            radioInputElement.checked = value === payment.description
                            radioInputElement.value = value
                            onRenderPaymentMethodEvent.onSelectPaymentMethodCallback()
                        })
                       
                        paymentMethodElement.addEventListener('click', function (ev) {
                            radioInputElement.checked = true
                            radioInputElement.dispatchEvent(new Event('change'))
                            onRenderPaymentMethodEvent.onSelectPaymentMethodCallback()
                        })
                        paymentMethodElement.appendChild(radioInputElement)
                        paymentMethodElement.appendChild(paymentItemElement)


                        let spin = document.getElementsByClassName('spinner')
                         spin[0].style.display = 'none'

                        if (window.parent != window) actions.appHeightChanged(iframeId);
                      

                        return paymentMethodElement
                    })
                   

                    paymentSession.injectPaymentMethods(document.getElementById('payment-methods'));
                   

                    document.getElementById('payment-button').onclick = function () {
                      
                        paymentSession.initiatePayment();
                    };
                               
                };
               
                window.document.body.appendChild(importScript);
               

            }
        });
      
    }                // did Mount ends here

   
    onRenderPaymentMethodCallback(method) {
       
       alert(method);
      
        
    }
   
    callbackSuccess() {

    }

    componentWillUnmount() {
        const { actions, iframeId } = this.props
        if (window.parent != window) actions.appHeightChanged(iframeId);

    }

    giftCardPaymentClicked() {
        const { showtime, organizerId, history } = this.props;
        history.push(`${ROUTES.ORDERFLOW.GIFTCARDS}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);
    }

    navBack() {
        const { history, disableBack, appLocale } = this.props;
        const { errors } = this.state;
        if (disableBack) this.setState({ errors: { ...errors, disableBack: LOCALE[appLocale].errorBack } });
        else history.goBack();
    }

    
    navNext() {
        const { organizerId, showtime, transactionId, customer, actions, totalPrice, history, giftCardAmount } = this.props;

        if (giftCardAmount > 0 && giftCardAmount < totalPrice && customer.creditCard || totalPrice === 0) {
            actions.preBook(transactionId, customer).then((result) => {
                if (!result.error) {
                    if (totalPrice === 0) {
                        actions.book(transactionId, 'Free', totalPrice)
                            .then((result) => { if (!result.error) history.push(`${ROUTES.ORDERFLOW.RECEIPT}/${showtime.movieId}/${showtime.id}?org=${organizerId}`) });
                    } else '';
                }
            });
        } else '';
    }

    paymentIsValid(payment) {
        const { totalPrice, appLocale, giftCardAmount } = this.props;
        if (totalPrice === 0) return true;
        else {
            const errors = {
                ...errors,
                creditCard: !payment.creditCard && (!giftCardAmount > 0 || giftCardAmount > 0 && giftCardAmount < totalPrice) ? LOCALE[appLocale].errorCreditCard : ''
            }

            this.setState({ errors });
            return errors.creditCard == '';
        }
    }

   

  
    render() {
        const { orderType, totalPrice, configuration, customer, actions, appLocale, disableBack, organizerId, history, showtime, creditCards, warning_texts } = this.props;
        const { errors, paymentMethodLoad, finalTktPrice, } = this.state;
        
        totalPrice !== this.state.lastPrice && this.state.lastPrice > -1 ? '' : this.state.lastPrice = totalPrice


        return (
            <>
            <div className="AltaPaymentContainer">
                <div className='content-container'>
               

                        <div className='giftCardContainer'>
                          
                            <GiftCardContainerAlta warning_texts={warning_texts} />
                        </div>


                   <div className='altaContainer'>
                            
                           
                        <section className='alta_altaContainer'>

                            {totalPrice === 0 ? <div></div> :
                                    <>
                                        { /*  loading spinner */ }
                                        {paymentMethodLoad == true &&
                                            <div style={{ position: 'relative', top: '30%' }}>
                                                <div class="spinner"></div>
                                            </div>
                                        }
                               <p className='paymethod_text'>{LOCALE[appLocale].payMethod}</p>
                               <div className='paymentMethodContainer' >
                                            <div id='payment-methods'  style={{ display: 'flex' }}> </div>
                               </div>
                                </>
                            }

                        </section>
                        <div className='theLastPriceCon'>
                                <p>  {LOCALE[appLocale].totalPrice} </p>
                            
                                <p>{totalPrice} {LOCALE[appLocale].currency}</p>
                        </div>
                    </div>

                </div>
                <div className='btncontent-container'>
                        <input className='back_button' onClick={this.navBack} type='button' value={LOCALE[appLocale].back}></input>
                        {totalPrice === 0 ? <button onClick={this.navNext} className="payment-button">{LOCALE[appLocale].payZero}</button> :
                           
                                <>                           
                                {totalPrice !== this.state.lastPrice && this.state.lastPrice > -1 ? this.preBookRecall() : ''}
                                 <button id="payment-button" >{LOCALE[appLocale].pay}</button>
                                </>
                           
                        }
                       
                   
                </div>
                
               
                </div >

            
                </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
  // console.log(state, 'state')
    return {
        organizerId: state.organizer.id,
        showtime: state.movielist.selectedShowtime,
        orderType: state.order.orderType,
        configuration: state.organizer.configuration,
        creditCards: state.organizer.creditcards || [],
        customer: state.customer,
        transactionId: state.order.selected.transactionId,
        receipt: state.receipt,
        totalPrice: state.order.totalPrice,
        clubCardId: state.user.clubCardId,
        appLocale: state.app.locale,
        disableBack: state.order.selected.giftCardAmount > 0,
        giftCardAmount: state.order.selected.giftCardAmount,
        iframeId: state.app.iframeId,
        warning_texts: state.organizer.configuration.texts
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, orderActions, customerActions, gaActions, { appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AltaPaymentContainer)